import { CHANGELOG_LINK } from "@/lib/constants";
import { classNames } from "@/lib/utils";
import { useChangelog } from "@/services/changelog.service";
import { ArrowUpRightIcon } from "@components/library/Icons";
import * as Popover from "@radix-ui/react-popover";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import useLocalStorageState from "use-local-storage-state";

export const Changelog = () => {
  const [open, setOpen] = useState(false);

  const [lastViewedTimestamp, setLastViewedTimestamp] = useLocalStorageState("changelog-last-viewed", {
    defaultValue: "0",
  });

  const { data, loading, error } = useChangelog();

  const isNew = useCallback(
    (update: any) => {
      return update?.pubDate ? new Date(update.pubDate).getTime() > Number(lastViewedTimestamp) : false;
    },
    [lastViewedTimestamp],
  );

  const hasUpdates = data?.some((update) => isNew(update));

  const markAsViewed = () => {
    const currentTimestamp = new Date().getTime();
    setLastViewedTimestamp(currentTimestamp.toString());
  };
  const handleMouseEnter = () => {
    setOpen(true);
  };
  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger
        asChild
        onClick={markAsViewed}
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleMouseEnter();
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* Some spacing hacks to make this bigger because can't do mouseLeave on parent MenuItem */}
        <a href={CHANGELOG_LINK} target="_blank" className="my-[-3] flex w-full items-center gap-10 py-[1.5px]">
          <ArrowUpRightIcon className="h-16 w-16 text-oldgray-700 hover:text-oldgray-800" />
          <div>Changelog</div>
          <span className={classNames("-ml-4 mb-6 h-6 w-6 rounded-full", hasUpdates ? "bg-oldblue-500" : "")} />
        </a>
      </Popover.Trigger>
      <Popover.Content
        asChild
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={markAsViewed}
        side="left"
        align="start"
        alignOffset={-10}
        sideOffset={-8}
      >
        {/* Using padding rather than sideOffset so that you can mouse over the gap
        and it won't disappear. */}
        <div className="pr-20 focus:outline-none focus:ring-0">
          {data && data?.length > 0 ? (
            <div className="z-[101] rounded border border-oldgray-400 bg-white shadow-5">
              <a
                className=" group flex  items-start justify-between gap-8 px-12 pb-10 pt-12 hover:bg-oldgray-100"
                href={CHANGELOG_LINK}
              >
                <h3 className="text-13-16 font-bold text-oldgray-800 group-hover:text-oldgray-900">Changelog</h3>
                <ArrowUpRightIcon className="h-14 w-14 text-oldgray-500 hover:text-oldgray-800" />
              </a>

              {data.slice(0, 5).map((update, index) => (
                <a
                  key={index}
                  href={update.link}
                  onClick={() => {
                    markAsViewed();
                  }}
                  className="group block border-t border-oldgray-300 px-12 py-10 hover:bg-oldgray-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="relative flex items-start justify-between">
                    <div>
                      <h5 className="mb-2 flex items-center gap-4 text-11-16 tabular-nums text-oldgray-700 group-hover:text-oldgray-800">
                        {dayjs(update.pubDate).format("YYYY-MM-DD")}
                        <div className={classNames("h-6 w-6 rounded-full", isNew(update) ? "bg-oldblue-500" : "")} />
                      </h5>
                      <h4 className="max-w-[220px] truncate text-12-16 text-oldgray-800 group-hover:text-oldgray-900">
                        {update.title}
                      </h4>
                    </div>
                    <ArrowUpRightIcon className="h-14 w-14 text-oldgray-500 group-hover:text-oldgray-600" />
                  </div>
                </a>
              ))}
            </div>
          ) : null}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export default Changelog;
