import { getAuthToken } from "@/lib/use-auth";
import useSWR, { SWRConfiguration } from "swr";

/*
Example setup schema format:
{
  "type": "object",
  "required": ["api_key"],
  "properties": {
    "api_key": {
      "type": "string",
      "description": "API key to authenticate SerpAPI tool"
     }
   }
}
*/
export type Property = {
  type: string;
  name: string;
  description?: string;
  enum?: string[];
  items?: {
    type: string;
    required: string[];
    value: { key: string; value: string }[];
  };
  // NB not complete list of properties, just the ones we use in the FE
};

// TS typehint for json schema? We do validate the correctness of the schema on BE.
// could use: https://www.npmjs.com/package/@types/json-schema
export type JsonSchema = {
  type: string;
  required: string[];
  properties: Record<string, Property>;
};

export interface ToolSource {
  source?: "inline" | "organization";
}

export interface LinkedTool extends ToolSource {
  source: "organization";
  id: string;
  name?: string;
  description?: string;
  parameters?: JsonSchema | null;
}

// Corresponds to the ToolConfig ToolDict type in the backend
export interface ToolResponse {
  id: string;
  name: string;
  description: string;
  parameters: JsonSchema | null;
  source: "inline" | "organization";
}

/** This is the Tool interface that works for Humanloop runtime tools and OpenAI functions. */
export interface EditorTool extends ToolSource {
  name: string;
  description: string;
  parameters: JsonSchema | null;
  source?: "inline";
}

export type ToolResult = {
  id: string;
  name: string;
  result: string;
  signature: string;
};

export const toolResponsesToToolsAndLinkedTools = (
  toolResponses: ToolResponse[],
): { tools: EditorTool[]; linkedTools: LinkedTool[] } => {
  const tools: EditorTool[] = toolResponses
    .filter((tool) => !isOrganizationLinkedTool(tool))
    .map((tool) => ({
      name: tool.name,
      description: tool.description,
      parameters: tool.parameters,
      source: tool.source,
    })) as EditorTool[];
  const linkedTools: LinkedTool[] = toolResponses.filter((tool) => isOrganizationLinkedTool(tool)) as LinkedTool[];
  return { tools, linkedTools };
};

// /** A variant of a Tool akin to a ModelConfig */
export interface ToolConfig extends EditorTool {
  id: string;
  signature: string | null;
  is_preset: boolean;
  setup_schema: JsonSchema | null;
  /** Source code if its available */
  // Preset tools are those which Humanloop own the runtime. The preset_name is the
  // unique key used to look up the runtime on Humanloop.
  // Note: not a fan of this naming, but we had these concepts existing in our config
  // related entities, so re-using for convenience.
  preset_name: string | null;
  other: any;
}

// The return type `tool is LinkedTool` is a TypeScript type predicate.
// It's a good practice as it informs the compiler about the type of tool when the function returns true.
export function isOrganizationLinkedTool(tool: EditorTool | LinkedTool): tool is LinkedTool {
  return "id" in tool && "source" in tool && tool.source === "organization";
}

export interface OrgToolConfig {
  id: string;
  // Organization level display name (maybe useful for the UI cards), not to be
  // confused with the tool name shown to the model, which lives in the ToolConfig
  name: string;
  setup_values: Record<string, any>;
  created_at: string;
  updated_at: string;
  status: "active" | "inactive";
  config: ToolConfig;
}
export const useOrgToolConfigs = (
  organizationId?: string,
  deployed: boolean = true,
  swrOptions: SWRConfiguration<OrgToolConfig[]> = {},
) => {
  const { data, error, mutate } = useSWR<OrgToolConfig[]>(
    organizationId
      ? [`/v4/organizations/${organizationId}/tools${deployed ? "?deployed=true" : ""}`, getAuthToken()]
      : null,
    swrOptions,
  );
  return { tools: data, error: error, loading: !data && !error, mutate };
};
