import useSWR, { SWRConfiguration } from "swr";

type ChangeLogItem = {
  title: string;
  description: string;
  link: string;
  pubDate: string;
};

const localFetcher = async (url: string) => fetch(url).then((res) => res.json());

export const useChangelog = (swrOptions: SWRConfiguration<ChangeLogItem[]> = {}) => {
  const { data, error, mutate } = useSWR<ChangeLogItem[]>(`/api/changelog`, localFetcher, swrOptions);
  return { data, error, loading: !data && !error, mutate };
};
