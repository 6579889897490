import * as Sentry from "@sentry/nextjs";
import { AxiosPromise } from "axios";
import { useMemo } from "react";
import useSWR, { SWRConfiguration } from "swr";
import { getAuthToken } from "../lib/use-auth";
import { ApiService } from "./api.service";
import {
  ModelConfigResponse,
  ProjectConfig,
  ProjectConfigResponse,
  SliderRange,
  parseProjectConfig,
} from "./configs.service";
import { ModelConfigRequest } from "./model-configs.service";
import SSE from "./sse.js";
import { File } from "@/types/app/file";
import { EditorTool, ToolResult } from "./v4.service";

export const enum ModelEndpoint {
  complete = "complete", //TODO: It would be better if this was "completion" and a string literal to match with playground modes
  chat = "chat",
  edit = "edit",
}

interface BaseGenerationRequest {
  /**  Project name, which if supplied will use the project endpoint and data will be logged. */
  project?: string;
  project_id?: string;
  session_id?: string;
  session_reference_id?: string;
  parent_id?: string;
  parent_reference_id?: string;
  inputs?: Record<string, any>;
  source?: string;
  metadata?: Record<string, any>;
}

export interface CompletionRequest extends BaseGenerationRequest {
  provider_api_keys: Partial<Record<ModelProvider, string>>;
  num_samples?: number;
  /** The model config when making a request uses the Tools format while the ModalConfigResponse uses the tool_config format */
  model_config: ModelConfigRequest;
  /** User email, which if supplied will be saved against the logged project data. */
  user?: string;
  // log_probs... // ignoring because not used and i don't trust the typing
  // stream ... // not including as we manage this elsewhere on FE.
  // suffix // not used
  // seed // isn't this part of the model config??
}

export interface ChatRequest extends CompletionRequest {
  messages?: ChatMessage[];
  tools?: EditorTool[];
}

// TODO: Why can't we use LogResponse consistently here too :(
// This mirrors DataResponse in backend/src/external/app/models/v4/completions.py
export interface DataResponse {
  /** Log ID `log_...`
  Historical logs start with data_ instead of log_ */
  id: string;
  /** The index of the sampled generation (you won't use this for n=1) */
  index: number;
  // For logs and generations these outputs are never null! It's only null because
  // of the annoying inheritance of PlaygroundPresetDatum
  output: string | null;
  raw_output: string | null;
  inputs: Record<string, any> | null;
  finish_reason: string | null;
  tool_results: ToolResult[];
  created_at: string;
  model_config_chat_template_messages_count: number;

  // TODO: why do we have messages here and on PlaygroundChatDataResponse? (peter)
  // Seems to be because of added complication of loading playground data/ PlaygroundDatumResponse (/laziness)
  // TODO: clean this up!
  messages?: ChatMessage[] | null;
  output_message: ChatMessage | null;
  // I'm very confused about what this is doing here... TODO: remove.
  // Avoid using! Use the output_message.tool_calls instead.
  // tool_calls?: ToolCall[] | null;
}

export interface ChatDataResponse extends DataResponse {
  messages: ChatMessage[] | null;
  output_message: ChatMessage;
}

export interface CompletionResponse {
  project_id: string | null;
  num_samples: number | null;
  user: string | null;
  data: DataResponse[];
  usage: Usage | null;
  metadata: Record<string, any>;
  provider_response: unknown;
  session_id: string | null;
}
export interface ChatResponse extends CompletionResponse {
  data: ChatDataResponse[];
  usage: Usage | null;
}

export interface FunctionTool {
  arguments: string;
  name: string;
}

export interface ToolCall {
  id: string;
  type: "function"; // This is the only valid type for chat completion mode for now
  function: FunctionTool;
}

export const complete = (request: CompletionRequest): AxiosPromise<CompletionResponse> => {
  return ApiService.post(`/v4/completion`, request);
};

export const streamCompletion = async ({
  request,
  onMessage,
  onError,
  onClose,
}: {
  request: CompletionRequest;
  onMessage: (message: any) => void;
  onError?: (error: any) => void;
  onClose?: () => void;
}): Promise<SSE> => {
  const source = ApiService.sse(`/v4/completion`, "POST", {
    ...request,
    stream: true,
    // Disable if streaming is false
    // If true then we will send an initial KEEPALIVE message at the start of the connection
    // This is useful for keeping the connection alive if the provider takes longer than 30s to respond
    // (which is the max timeout vercel will support without a response)
    stream_early_response: true,
  });
  if (source.addEventListener === undefined || source.stream === undefined) {
    throw new Error("SSE does not have addEventListener method");
  }
  source.addEventListener("message", onMessage);
  if (onError) source.addEventListener("error", onError);
  if (onClose) {
    source.addEventListener("readystatechange", () => {
      if (source.readyState === 2) onClose();
    });
  }
  source.stream();
  return source;
};

export type ChatMessageRole = "user" | "assistant" | "system" | "tool" | "error";
export const CHAT_MESSAGE_ROLES: ChatMessageRole[] = ["user", "assistant", "system", "tool"];

export interface TextChatContent {
  type: "text";
  text: string;
}

type ImageUrlDetail = "high" | "low" | "auto";

export interface ImageChatContent {
  type: "image_url";
  image_url: {
    url: string;
    detail?: ImageUrlDetail;
  };
}

export type ChatMessageContent = TextChatContent | ImageChatContent;

export interface ChatMessage {
  role: ChatMessageRole;
  content?: string | ChatMessageContent[];
  name?: string | null;
  tool_call_id?: string | null;
  tool_calls?: ToolCall[] | null; // Needed for openai functions
}

// TODO: Make difference between ChatMessage and ChatMessageData more explicit/explained (or unify them)
export interface ChatMessageData extends ChatMessage {
  // TODO: _id should be made not optional. I think only reason it is, is because we get messages from API and then add _id in if needed.
  _id?: string; // ID for the message to identify it in the UI and rendered components. Not populated by the API.
  _status?: "loading" | "not_loading"; // Status of the message being generated. Not populated by the API.
  logId?: string;
}

export const chat = (request: ChatRequest): AxiosPromise<ChatResponse> => {
  return ApiService.post(`/v4/chat`, request);
};

export const streamChat = async ({
  request,
  onMessage,
  onError,
  onClose,
}: {
  request: ChatRequest;
  onMessage: (message: any) => void;
  onError?: (error: any) => void;
  onClose?: () => void;
}): Promise<SSE> => {
  const source = ApiService.sse(`/v4/chat`, "POST", {
    ...request,
    stream: true,
    // Disable if streaming is false
    // If true then we will send an initial KEEPALIVE message at the start of the connection
    // This is useful for keeping the connection alive if the provider takes longer than 30s to respond
    // (which is the max timeout vercel will support without a response)
    stream_early_response: true,
  });
  if (source.addEventListener === undefined || source.stream === undefined) {
    throw new Error("SSE does not have addEventListener method");
  }
  source.addEventListener("message", onMessage);
  if (onError) source.addEventListener("error", onError);
  if (onClose) {
    source.addEventListener("readystatechange", () => {
      if (source.readyState === 2) {
        onClose();
      }
    });
  }
  source.stream();
  return source;
};

/**
 * Fetch the models available to the user.
 *
 * This will use the provided API keys in preference to any org level keys.
 */
export const usePlaygroundModels = (
  providerApiKeys: Record<ModelProvider, string>,
  swrOptions: SWRConfiguration<Model[]> = {},
) => {
  // POST to the models endpoint with the providerApiKeys in the body.
  const { data, error, mutate } = useSWR<Model[]>(
    [`/v4/playground/models`, getAuthToken(), providerApiKeys],
    // Custom fetcher to POST the provider_api_keys in the body (to keep them out of the URL)
    async ([url, authToken, providerApiKeys]) => {
      const response = await ApiService.post(url, providerApiKeys);
      return response.data;
    },
    swrOptions,
  );

  return { models: data, error: error, loading: !data && !error, mutate };
};

export interface PlaygroundProjectModelConfigs {
  // Uses project on api response, will change to File in v5
  project: File;
  model_configs: ProjectConfigResponse[];
}

export const usePlaygroundModelConfigs = (
  projectId?: string,
  swrOptions: SWRConfiguration<PlaygroundProjectModelConfigs[]> = {},
) => {
  const params = new URLSearchParams();
  if (projectId) {
    params.append("project_id", projectId);
  }

  const { data, error, mutate } = useSWR<PlaygroundProjectModelConfigs[]>(
    projectId ? [`/v4/playground/model-configs?${params.toString()}`, getAuthToken()] : null,
    swrOptions,
  );
  return { data, error, loading: !data && !error, mutate };
};

/** Convenience around use PlaygroundModelConfigs to get a single model config
 *
 *  Note: not using the project config endpoint! */
export const useProjectConfig = ({
  modelConfigId,
  projectId,
}: {
  modelConfigId: string | null;
  projectId?: string;
}) => {
  // TODO: this should probably be an API rather than a filter on the client.
  const { data, error, loading, mutate } = usePlaygroundModelConfigs(projectId);
  const projectConfig: ProjectConfig | undefined = useMemo(() => {
    if (modelConfigId === null) return undefined;

    // This currently takes the first match of the MC id, but the same ID can be used in multiple projects.
    let projectConfig: ProjectConfigResponse | undefined;
    if (data) {
      for (const projectWithConfigs of data) {
        // TODO: This will change to a .file.id in v5
        if (projectId && projectWithConfigs.project.id !== projectId) continue;
        if (projectWithConfigs.model_configs.find((pmc, i) => pmc.config.id === modelConfigId)) {
          projectConfig = projectWithConfigs.model_configs.find((pmc, i) => pmc.config.id === modelConfigId);
          break;
        }
      }
    }
    return projectConfig ? parseProjectConfig(projectConfig) : undefined;
  }, [data, modelConfigId, projectId]);

  return { projectConfig, error, loading, mutate };
};

export const nameModelConfig = (): AxiosPromise<string> => {
  return ApiService.get(`/v4/playground/generate-name`);
};

// TODO: I think this could use the Logs endpoint now that we've got rid of shared history loading etc.
export const getPlaygroundData = (ids: string[]): AxiosPromise<DataResponse[]> => {
  const params = new URLSearchParams();
  ids.forEach((id) => params.append("ids", id));

  return ApiService.get(`/v4/playground/data?${params.toString()}`);
};

export interface Usage {
  prompt_tokens: number;
  generation_tokens: number;
  total_tokens: number;
}
export const enum ModelProvider {
  openai = "openai",
  openai_azure = "openai_azure",
  ai21 = "ai21",
  mock = "mock",
  bedrock = "bedrock",
  anthropic = "anthropic",
  deepmind = "deepmind",
  cohere = "cohere",
  huggingface = "huggingface",
  replicate = "replicate",
  google = "google",
  groq = "groq",
  deepseek = "deepseek",
}
export interface Model {
  id: string; // Our dropdown component needs an id string defined.
  name: string;
  provider: ModelProvider;
  hidden: boolean;
  fine_tuned: boolean;
  key: string;
  default_prompt_template?: string;
  endpoint?: ModelEndpoint;
  custom_parameters?: CustomParameters;
  image_support: boolean;
  tool_support: boolean;
  max_prompt_tokens: number;
  max_output_tokens: number;
  cost_per_prompt_token: number | null;
  cost_per_output_token: number | null;
  reasoning_effort_support: boolean;
  json_schema_response_support: boolean;
}

export interface CustomParameters {
  frequency_penalty: SliderRange;
  presence_penalty: SliderRange;
}
