import _ from "lodash";
import { parse } from "tldts";
import freeEmailDomains from "free-email-domains";

/**
 * Converts the given string into title case if it is in snake or camel case.
 * @param text
 * @returns String
 *
 * @example
 * titleFromSnakeOrCamel("my_variable_name") // "My Variable Name"
 * titleFromSnakeOrCamel("myVariableName") // "My Variable Name"
 * titleFromSnakeOrCamel("myVariableName") // "My Variable Name"
 */
export const titleFromSnakeOrCamel = (text: string): string => {
  if (text === _.snakeCase(text)) {
    return _.startCase(text);
  }
  if (text === _.camelCase(text)) {
    return _.startCase(text);
  }
  return text;
};

/** Returns a unique name by appending a number to the end of the name
 * // https://chat.openai.com/share/8f4e411b-5ec1-42bc-8e1d-8fe249817f2e
 */
export function getUniqueName(name: string, existingNames: string[]): string {
  let suffix = 0;
  let newName = name;
  while (existingNames.includes(newName)) newName = `${name}${++suffix}`;
  return newName;
}

/**
 * Converts the given string into a two letter abbreviation
 *
 * @param text
 * @returns String
 *
 * @example
 *
 * abbreviate('Google Ads') // 'GA'
 * abbreviate('Meta tags') // 'MT'
 * abbreviate('Summariser') // 'Su'
 * abbreviate('Ask Paul Graham') // 'AP'
 * abbreviate('adam.longhort@gmail.com') // 'AL'
 * abbreviate('michael@gmail.com') // 'Mi'
 * abbreviate('Jordan (jordan@humanloop.com)') // 'Jo'
 * */
export const abbreviate = (text?: string): string => {
  if (!text) {
    return "";
  }
  // Split the text into words on spaces, dashes, and underscores, ignoring anything after @ or ()
  const words = text
    .split("@")[0]
    .split("(")[0]
    .split(/[\s\.\-_]/);
  if (words.length === 1) {
    return text.slice(0, 1).toUpperCase() + text.slice(1, 2).toLowerCase();
  }
  return words
    .slice(0, 2)
    .map((word) => word[0])
    .join("")
    .toUpperCase();
};

export const getSlugFromEmail = (email: string): string => {
  const parsed = parse(email);
  if (parsed.domain === null) return "";
  if (isPublicEmailProvider(parsed.domain)) return "";
  return parsed.domainWithoutSuffix || "";
};

export const getSlugFromName = (name: string): string => {
  /**
   *   These are the requirements for a slug:
   *     (The last requirement is the definitive one, and the ones before that check specific aspects for better error messages.)
   *   .min(2, "Minimum of 2 characters.")
   *   .max(64, "Maximum of 64 characters.")
   *   .matches(/^[a-z0-9-]*$/, "Can only contain letters, numbers and hyphens.")
   *   .matches(/^[a-z0-9]+(([a-z0-9]*)\-?)*[a-z0-9]+$/, "Must start and end with a letter or number.")
   *
   *   This converts an org name into a slug
   *
   * 1. Convert to lowercase
   * 2. Remove all non-alphanumeric characters
   * 3. Replace all spaces with hyphens
   * 4. Remove all hyphens from the start and end of the string
   **/

  return name
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const isPublicEmailProvider = (email: string): boolean => {
  const domain = parse(email).domain;
  if (domain === null) {
    return false;
  }
  return freeEmailDomains.includes(domain);
};

export const isPublicEmailDomain = (domain: string): boolean => {
  return freeEmailDomains.includes(domain);
};

export const isValidEmailDomain = (domain: string): boolean => {
  /**
   * Domain section of general email regex (RFC 5322 Official Standard)
   * https://emailregex.com/
   **/
  const regex = new RegExp(/^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return regex.test(domain);
};

export const getCompanyEmailDomain = (email: string, startWithAt: boolean = true): string => {
  /** Returns @company.com of any non-public email address
   * @example
   * getCompanyEmailDomain('mike@gmail.com') // ""
   * 'boss@hugoboss.com' -> '@hugoboss.com'
   **/
  if (!email) return "";
  const domain = parse(email).domain;
  if (domain === null) return "";
  if (isPublicEmailProvider(email)) return "";
  return startWithAt ? `@${domain}` : domain;
};

export const getShortId = (id: string, limit: number = 5): string => {
  const withoutPrefix = id.includes("_") ? id.slice(id.split("_", 1)[0].length + 1) : id;
  return withoutPrefix.slice(0, limit);
};

/** Slice the full ID into three parts: prefix, shortID, and remainder */
export const segmentLongId = (
  id: string,
  limit: number = 5,
): { prefix: string; shortId: string; remainder: string } => {
  const prefix = id.split("_", 1)[0] + (id.includes("_") ? "_" : "");
  const shortId = getShortId(id, limit);
  const remainder = id.slice(prefix.length + shortId.length + 1);
  return { prefix, shortId, remainder };
};
