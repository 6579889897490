import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { useTabContext } from "@/context/TabContextProvider";
import { EvaluatorType } from "@/types/app/evaluator";
import { File, FileType } from "@/types/app/file";
import { getFileTypeFromId } from "@components/library/utils/versions";
import { CogIcon } from "@heroicons/react/outline";
import { ChartBar, Cube, PencilSimple, Icon as PhosphorIcon, Rewind, SquaresFour } from "@phosphor-icons/react";
import { useRouter } from "next/router";

export type AppHrefTarget =
  | {
      type: "directory";
      id: string | "home";
    }
  | {
      type: "file";
      id: string;
      page?: FilePage | null; // If undefined, the current page is inferred from the URL. If null, the current page is not included in the href.
    };

// Regex whose match group extracts the current page within a file from the URL.
const FILE_PAGE_REGEX = /\/project\/\[projectId\]\/([\w-]*)/;

const inferFilePageFromUrl = (projectId: string): FilePage | undefined => {
  const pathName = window.location.pathname;
  const match = FILE_PAGE_REGEX.exec(pathName);
  if (match) {
    const currentPage = match[1] as FilePage;
    if (filePagesForFileType(getFileTypeFromId(projectId)).includes(currentPage)) {
      return currentPage;
    }
  }
  return undefined;
};

/** Create a target URL for a file/directory
 *
 *  This handles the need to know the current organization and the last selected tab.
 *  */
export const useAppHref = (target: AppHrefTarget): string => {
  const router = useRouter();
  const { slug } = useActiveOrganization();
  const { lastSelectedTab } = useTabContext();
  // Note that while `slug` is typed to be nullable, the comment there says it falls back to an empty string and isn't actually nullable.
  if (!slug || slug.length === 0) {
    return "";
  }

  switch (target.type) {
    case "directory":
      return getDirectoryHref({ slug, id: target.id });
    case "file":
      // If page is specified, go there, otherwise use the last selected tab, otherwise fallback to the default page for the file type.
      const page =
        target.page ||
        (lastSelectedTab && filePagesForFileType(getFileTypeFromId(target.id)).includes(lastSelectedTab)
          ? lastSelectedTab
          : undefined) ||
        inferFilePageFromUrl(target.id) ||
        getDefaultFilePage(getFileTypeFromId(target.id));
      return getFileHref({ slug, id: target.id, page });
  }
};

export const getDirectoryHref = ({ slug, id }: { slug: string; id: string }): string => {
  return `/${slug}?id=${id}`;
};

export const getFileHref = ({ slug, id, page }: { slug: string; id: string; page: FilePage }): string => {
  return `/${slug}/project/${id}/${page}`;
};

type OrganizationSettingsPage = "organization" | "members" | "api-keys" | "environments" | "billing";

export const getOrganizationSettingsHref = ({
  slug,
  page,
}: {
  slug: string;
  page: OrganizationSettingsPage;
}): string => {
  const slugPart = !slug ? "" : `/${slug}`;
  return `${slugPart}/account/${page}`;
};

/**
 * Infers the current location from the URL.
 *
 * Should be the inverse of `useAppHref`.
 */
export const useCurrentLocationFromHref = (): AppHrefTarget | undefined => {
  const router = useRouter();
  const projectId = router.query.projectId as string | undefined;
  if (projectId) {
    const pathName = router.pathname;
    const match = FILE_PAGE_REGEX.exec(pathName);
    if (match) {
      const tab = match[1] as FilePage;
      return { type: "file", id: projectId, page: tab };
    }
    return { type: "file", id: projectId };
  }

  const dirId = router.query.id as string | undefined;

  if (dirId) {
    return { type: "directory", id: dirId };
  }

  if (router.pathname === `/[orgSlug]`) {
    return { type: "directory", id: "home" };
  }

  return undefined;
};

export const getNodeIdForProjectTab = (projectId: string, tab: FilePage): string => {
  return `${projectId}/${tab}`;
};

export const FILE_PAGES = ["dashboard", "editor", "logs", "sessions", "evaluations", "settings"] as const;
export type FilePage = (typeof FILE_PAGES)[number];

export const getDefaultFilePage = (fileType: FileType): FilePage => {
  switch (fileType) {
    case "flow":
      return "dashboard";
    default:
      return "editor";
  }
};

export const FILE_PAGE_NAMES: Record<FilePage, string> = {
  dashboard: "Dashboard",
  editor: "Editor",
  logs: "Logs",
  sessions: "Sessions",
  evaluations: "Evals",
  settings: "Settings",
};

export const FILE_PAGE_ICONS: Record<FilePage, ((props: React.ComponentProps<"svg">) => JSX.Element) | PhosphorIcon> = {
  dashboard: SquaresFour,
  editor: PencilSimple,
  logs: Cube,
  sessions: Rewind,
  evaluations: ChartBar,
  settings: CogIcon,
};

/**
 * Get the list of tabs to show for the specified FileType.
 *
 * `evaluatorType` is optional and only used for Evaluator files, where
 * it determines whether the Evaluations tab should be shown.
 */
export function filePagesForFileType(fileType: FileType | "unknown", evaluatorType?: EvaluatorType | null): FilePage[] {
  switch (fileType) {
    case "prompt":
      return ["dashboard", "editor", "logs", "evaluations", "settings"];
    case "tool":
      return ["dashboard", "editor", "logs", "evaluations", "settings"];
    case "dataset":
      return ["dashboard", "editor"];
    case "evaluator":
      switch (evaluatorType) {
        case "llm":
          // Show evaluations tab for LLM Evaluators
          return ["dashboard", "editor", "logs", "evaluations", "settings"];
        case "human":
          return ["dashboard", "editor", "logs", "settings"];
        case "external":
          // No Editor tab for External Evaluators
          return ["dashboard", "logs", "settings"];
        default:
          // Setting it so if unknown, we show all tabs, and let the page redirect them as needed.
          return ["dashboard", "editor", "logs", "evaluations", "settings"];
      }
    case "flow":
      return ["dashboard", "logs", "evaluations"];
    default:
      return ["dashboard", "editor", "logs", "settings"];
  }
}

/**
 * Get the list of pages for a File.
 *
 * A convenience on top of `filePagesForFileType`.
 */
export const filePagesForFile = (
  file: { type: FileType; spec?: { evaluator_type?: EvaluatorType | null } } | File,
): FilePage[] => {
  // Handle both File type and object with evaluator_type
  const evaluatorType =
    "spec" in file && file.spec && "evaluator_type" in file.spec
      ? file.spec.evaluator_type
      : "evaluator_type" in file
        ? (file as any).evaluator_type
        : undefined;

  return filePagesForFileType(file.type, evaluatorType);
};

/**
 * Fetches whether the File of a FileType has access to a given page.
 *
 * E.g. To check if a File has access to Editor.
 */
export const fileHasPage = (
  file: { type: FileType; evaluator_type?: EvaluatorType | null } | File,
  page: FilePage,
): boolean => {
  // Extra handling to support our DirectoryFile type and File types.
  const fileType = file.type;
  const evaluatorType: EvaluatorType =
    "spec" in file && "evaluator_type" in file.spec
      ? file.spec.evaluator_type
      : "evaluator_type" in file
        ? (file as any).evaluator_type
        : undefined;

  const pages = filePagesForFileType(fileType, evaluatorType);
  return pages.includes(page);
};

export const pathIsWithinProject = (url: string, slug: string): boolean => {
  return url.startsWith(`/${slug}/project/pr_`);
};

export const pathIsProjectEditor = (url: string): boolean => {
  return /\/project\/pr_[a-zA-Z0-9]*\/editor/.test(url);
};
