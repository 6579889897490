import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { PRIVACY_CLASSNAMES } from "@/lib/analytics/shared";
import { getOrganizationSettingsHref } from "@/lib/path-utils";
import Button from "@components/library/Button";
import { ArrowUpRightIcon, ChatInfoIcon } from "@components/library/Icons";
import { Placement } from "@floating-ui/utils";
import { Float } from "@headlessui-float/react";
import { Menu } from "@headlessui/react";
import { CogIcon, KeyIcon, LogoutIcon, UserCircleIcon, UserGroupIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Avatar, { AvatarSize } from "components/atoms/Avatar";
import { ACCOUNT_SETTINGS_URL, DOCS_LINK } from "lib/constants";
import { useAuth } from "lib/use-auth";
import { getActiveOrganizationMembership } from "lib/user";
import { classNames } from "lib/utils";
import _ from "lodash";
import Link from "next/link";
import Changelog from "./Changelog";

interface Props {
  hideName?: boolean;
  hideLinks?: boolean;
  hideChevron?: boolean;
  avatarSize?: AvatarSize;
  placement?: Placement;
}

export default function ProfileDropdown({ hideName, hideLinks, hideChevron, avatarSize = 28, placement }: Props) {
  const { user, logout } = useAuth();
  const { slug } = useActiveOrganization();

  if (!user) {
    return <></>;
  }
  const organizationMembership = getActiveOrganizationMembership(user);

  return (
    <Menu as="div" className="relative ml-4 inline-flex min-w-0">
      <Float strategy="fixed" offset={8} placement={placement || "bottom-end"}>
        <Menu.Button
          as={Button}
          styling={"ghost"}
          className="group flex min-w-0 flex-1 shrink items-center gap-8 truncate "
        >
          <span className="sr-only">Open user menu</span>
          <Avatar className={classNames("-ml-4", PRIVACY_CLASSNAMES["low"])} user={user} size={avatarSize} />
          {!hideName && (
            <span
              className={classNames(
                "min-w-0 shrink truncate text-12-16 font-medium text-text-base-2 group-hover:text-text-base-1",
                PRIVACY_CLASSNAMES["low"],
              )}
            >
              {user.full_name || user.email_address}
            </span>
          )}
          {!hideChevron && <ChevronDownIcon className="ml-8 h-16 w-16 text-oldgray-700 group-hover:text-oldgray-800" />}
        </Menu.Button>
        <Menu.Items
          className={classNames(
            "z-50 w-224 divide-y overflow-hidden rounded border-oldgray-400 bg-white shadow-c ring-1 ring-black ring-opacity-5 focus:outline-none",
          )}
        >
          <div className="flex flex-col items-center gap-12 px-24 py-20">
            <Avatar user={user} size={56} />
            <div className="flex w-full flex-col items-center truncate">
              <div className="w-full truncate px-16 py-8 text-center text-14-16 font-bold text-oldgray-900">
                {user.full_name}
              </div>
              {organizationMembership && (
                <div className="flex w-full items-center justify-center gap-6 truncate text-12-14 text-oldgray-600">
                  <div>{_.startCase(organizationMembership.role)}</div>
                  <div className="h-4 w-4 rounded-full bg-oldgray-500" />
                  <div className="truncate">{organizationMembership.organization.name}</div>
                </div>
              )}
            </div>

            {/* Can be logged in and unverified. We show this dropdown during the sign up flow. */}
            {!user.verified && (
              <div className="px-8 pb-2 text-14-20 text-oldgray-700">
                <span className="flex items-center rounded-full bg-oldyellow-100 px-10 py-2 text-12-16 text-oldyellow-800">
                  Unverified
                </span>
              </div>
            )}
          </div>

          {!hideLinks && (
            <div className="flex flex-col py-8">
              <Link href={ACCOUNT_SETTINGS_URL}>
                <MenuItem as="div">
                  <UserCircleIcon className="h-16 w-16" />
                  <div>My account</div>
                </MenuItem>
              </Link>

              {organizationMembership && (
                <>
                  <Link href={getOrganizationSettingsHref({ slug, page: "organization" })}>
                    <MenuItem as="div">
                      <CogIcon className="h-16 w-16" />
                      <div>Organization Settings</div>
                    </MenuItem>
                  </Link>

                  <Link
                    href={getOrganizationSettingsHref({
                      slug,
                      page: "members",
                    })}
                  >
                    <MenuItem as="div">
                      <UserGroupIcon className="h-16 w-16" />
                      <div>Invite others</div>
                    </MenuItem>
                  </Link>

                  {/* NB this page isn't available to non-admins and so will redirect elsewhere.
                //  Not an issue for now (everyone's admin!)
                // But there's a TODO: to make this page available (but limited) to non-admins.
                //  */}
                  <Link href={getOrganizationSettingsHref({ slug, page: "api-keys" })}>
                    <MenuItem as="div">
                      <KeyIcon className="h-16 w-16" />
                      <div>API Keys</div>
                    </MenuItem>
                  </Link>
                </>
              )}
            </div>
          )}
          <div className="flex flex-col border-t border-oldgray-300 bg-oldgray-100 py-8">
            {user && (
              <>
                <Link href={DOCS_LINK} target="_blank" className="grow">
                  <MenuItem as="div">
                    <ArrowUpRightIcon className="h-16 w-16" />
                    <div>Docs</div>
                  </MenuItem>
                </Link>
                <MenuItem as="div">
                  {/* Can't attach mouseLeave events to MenuItem so open/close state all managed internally */}
                  <Changelog />
                </MenuItem>
              </>
            )}

            <MenuItem
              as="button"
              onClick={() => {
                Intercom("show");
              }}
            >
              <ChatInfoIcon className="h-16 w-16" />
              Help
            </MenuItem>

            <MenuItem as="button" onClick={() => logout()}>
              <LogoutIcon className="h-16 w-16" />
              Sign out
            </MenuItem>
          </div>
        </Menu.Items>
      </Float>
    </Menu>
  );
}

type MenuItemProps = Parameters<typeof Menu.Item>[0];
// Would like to use React.ComponentProps<Menu.Item>, but Headless UI has broken this.
// See: https://github.com/tailwindlabs/headlessui/issues/1394#issuecomment-1267599206

const MenuItem = (props: MenuItemProps) => {
  return (
    <Menu.Item
      className={classNames(
        "flex items-center gap-10 px-12 py-6 text-12-16 text-oldgray-700 ui-active:bg-oldgray-200 ui-active:text-oldgray-900 ui-not-active:hover:bg-oldgray-200 focus:bg-oldgray-200 focus:outline-none hover:active:bg-oldgray-300 ",
      )}
      {...props}
    />
  );
};
