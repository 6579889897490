import { PRIVACY_CLASSNAMES } from "@/lib/analytics/shared";
import { abbreviate } from "@/lib/string";
import { Avatar as EvergreenAvatar } from "evergreen-ui";
import { classNames } from "lib/utils";
import md5 from "md5";
import { memo } from "react";

function gravatarURI(email: string) {
  const emailHash = md5(email.trim().toLowerCase() || "");
  const fallbackUrl = "https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png";
  return `https://www.gravatar.com/avatar/${emailHash}?d=${encodeURIComponent(fallbackUrl)}`;
}

export interface AvatarUser {
  full_name?: string | null;
  email_address: string;
  avatar_url?: string;
}

export type AvatarSize = 24 | 28 | 32 | 40 | 56;

interface AvatarProps {
  user?: AvatarUser;
  size?: AvatarSize;
  className?: string;
}

function Avatar({ user, size = 32, className }: AvatarProps): JSX.Element {
  const email = user?.email_address;
  const name = user?.full_name || email;
  const imageURI = user?.avatar_url ? user.avatar_url : email ? gravatarURI(email) : undefined;

  return (
    <EvergreenAvatar
      className={classNames("hl-avatar", className, PRIVACY_CLASSNAMES["low"])}
      forceShowInitials={true}
      getInitials={abbreviate}
      size={size}
      src={imageURI}
      name={`${name} (${email})`}
      // TODO: explain what this is for @peter
      hashValue={imageURI}
      // TODO: explain what this is for @peter
      referrerPolicy="no-referrer"
    />
  );
}

// TODO: This memo does not re-render when avatarUrl changes. Ideas?
const MemoizedAvatar = memo(Avatar);

export default Avatar;
